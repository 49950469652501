// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/data/apps.js"
);
import.meta.hot.lastModified = "1714778144244.963";
}
// REMIX HMR END

import playStore from "../images/playstore.svg";
import appStore from "../images/appstore.svg";
const apps = [
  {
    icon: playStore,
    name: "Google Play Store",
    link: "#",
  },
  {
    icon: appStore,
    name: "Apple App Store",
    link: "#",
  },
];

export default apps;
